import { memo, useState } from "react"
import styled from "styled-components";
import { device } from "../styles/stylesConfig";
import { useSelector } from "react-redux";

const ImageLogo = styled.img`
    width: 600px;
    max-width: 70%;
    height: auto;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 25vh;

    @media ${device.md} {
        bottom: 20vh;
    }

    @media ${device.sm} {
        bottom: 14vh;

    }

`

const SubImageLogo = styled.img`
    width: 500px;
    max-width: 50%;
    height: auto;
    position: absolute;
    margin: auto;
    top: 0vh;
    bottom: 0;
`

const Logos = () => {

    const { primaryColor } = useSelector((state: any) => state.ui);

    const [mainLogoLoaded, setMainLogoLoaded] = useState(false);

    return (

        <>

            <ImageLogo
                loading="eager"
                src={`${process.env.REACT_APP_CDN}/general/wasabi${primaryColor}.webp`}
                alt="wasabi"
                style={{ visibility: mainLogoLoaded ? 'visible' : 'hidden' }}
                onLoadCapture={() => {
                    setMainLogoLoaded(true)
                }}
            />

            <SubImageLogo
                loading="eager"
                src={`${process.env.REACT_APP_CDN}/general/picante${primaryColor}.webp`}
                alt="picante"
                style={{ visibility: mainLogoLoaded ? 'visible' : 'hidden' }}

            />

        </>

    )
}

const MemorizedLogos = memo(Logos);

export default MemorizedLogos;