import React, { useEffect, useState, Suspense, lazy } from "react"
import { useLocation } from "react-router-dom"
import { scrollFadeInBottom, scrollFadeInCenter, scrollFadeInFastCenter, scrollFadeInFastLeft, scrollFadeInFastRight, scrollFadeInFastTop, scrollFadeInLeft, scrollFadeInRight, scrollFadeInTop } from "../effects/Observers";
import { useSelector } from "react-redux"
import Building from "../components/Building";
import styled from "styled-components";
// import { NextDates } from "../components/NextDates";
// import { Moments } from "../components/Moments";
import { getFullDate } from "../utils/Utilities";
import { NewsletterModal } from "../components/NewsletterModal";
import { collection, onSnapshot } from "firebase/firestore";
import { firestore } from "../firebase/firebase-config";

const Header = lazy(() => import("../components/Header"));
const Introduction = lazy(() => import("../components/Introduction"));
const HottestParty = lazy(() => import("../components/HottestParty"));
const FindYou = lazy(() => import("../components/FindYou"));
const NextDates = lazy(() => import("../components/NextDates"));
const Moments = lazy(() => import("../components/Moments"));
const About = lazy(() => import("../components/About"));
const Social = lazy(() => import("../components/Social"));
const Rights = lazy(() => import("../components/Rights"));

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
`

export const HomeScreen = () => {

    const [showPreloader, setShowPreloader] = useState(true);

    const [showNewsletter, setShowNewsletter] = useState(false);

    const [passwordOk, setPasswordOk] = useState((localStorage.getItem('passwordExpire') !== undefined && localStorage.getItem('passwordExpire') !== null && parseInt(getFullDate()) < parseInt(localStorage.getItem('passwordExpire')!)) ?? false);

    const location = useLocation();

    const { loading, primaryColor } = useSelector((state: any) => state.ui);

    const preloader = () => {

        if (!loading) {

            setTimeout(() => {
                setShowPreloader(false);

                setTimeout(() => {
                    setShowNewsletter((localStorage.getItem('newsletterExpire') === undefined || parseInt(getFullDate()) > parseInt(localStorage.getItem('newsletterExpire') ?? '0')) ?? false);
                }, 1500);

            }, 1000);

        }

    };

    useEffect(() => {

        localStorage.setItem("primaryColor", `${primaryColor}`);

        preloader()

        scrollFadeInTop();
        scrollFadeInFastTop();

        scrollFadeInBottom();

        scrollFadeInCenter();
        scrollFadeInFastCenter();

        scrollFadeInRight();
        scrollFadeInFastRight();

        scrollFadeInLeft();
        scrollFadeInFastLeft();

    }, [location.pathname, loading])

    if (process.env.REACT_APP_IN_PROGRESS === '1' && !passwordOk) return <Building showInProgress passwordOk={passwordOk} setPasswordOk={setPasswordOk} />

    return (

        <Container>

            {showPreloader && <Building showInProgress={false} passwordOk={passwordOk} setPasswordOk={setPasswordOk} />}

            <Suspense fallback={<Building showInProgress={false} passwordOk={passwordOk} setPasswordOk={setPasswordOk} />}>

                <Header />

                {showNewsletter && <NewsletterModal setVisible={setShowNewsletter} />}

                {/* Banner */}

                <Introduction />

                {/* Entradas */}

                <NextDates />

                {/* Momentos Wasabi */}

                <Moments />

                {/* La fiesta mas picante */}

                <HottestParty />

                {/* Buscate */}

                <FindYou />

                {/* Historia Wasabi */}

                <About />

                {/* Spotify + redes */}

                <Social />

                {/* Derechos */}

                <Rights />

            </Suspense>

        </Container>

    )
}
